import React, { Component } from 'react';
import { Routes, Route, useParams } from "react-router-dom";

import './MainContent.scss';

import AboutPage from './../../pages/AboutPage';
import HomePage from './../../pages/HomePage';
import ProductsPage from './../../pages/ProductsPage';
import ProjectsPage from './../../pages/ProjectsPage';
import SwitchToGaming from '../switchToGaming/SwitchToGaming';

export default class MainContent extends Component {
  render() {
    return (
      <div className="">
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/products" element={<ProductsPage />}/>
          <Route path="/projects" element={<ProjectsPage />}/>
          <Route path="/about" element={<AboutPage />}/>
          <Route path="/gaming" element={<SwitchToGaming />}/>
        </Routes>
      </div>
    );
  }
}
