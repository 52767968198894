import React from 'react';
import { Row, Col, Image } from '../bootstrap/Bootstrap';
import { useQuery, gql } from '@apollo/client';

import './Messages.scss';

import { product_image_path } from '../../Config';

const MESSAGES = gql`
query {
    topMessages(amount: 10) {
      id
      date
      image
      title
      text
      linktext
      link
    }
}`;

const Messages = () => {
  const { loading, error, data } = useQuery(MESSAGES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return data.topMessages.map((item) => (
    <Row key={item.id} className="message pt-3">
      <Col className="message-text justify-content-start">
        <div className='title'>{item.title}</div>
        <div className='date'>{item.date}</div>
        <div className='text'>{item.text}</div>
      </Col>
    </Row>
  ));
};

export default Messages;
