import React from 'react'

import "./Footer.scss";

import LinkTreeIcon from './../../resources/images/linktree-icon.png';
import TwitterIcon from './../../resources/images/twitter-icon.png';

export default function Footer() {
    return (
        <nav className="page-footer navbar fixed-bottom">
            <div className="">
                <a className="navbar-brand" href="https://linktr.ee/Random_rambling_press" target="_blank">
                    <img src={LinkTreeIcon} className='footer-icon'/>
                </a>
                <a className="navbar-brand" href="https://twitter.com/PressRambling" target="_blank">
                    <img src={TwitterIcon} className='footer-icon'/>
                </a>
            </div>
            <div>
                Updated: 2022-05-12
            </div>
        </nav>
    )
}
