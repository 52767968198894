import React, { Component } from 'react';

import {
  FluidContainer,
  Container,
  Row,
  Col,
  Image
} from './../components/bootstrap/Bootstrap';

import centerImage from './../resources/images/troy.jpg';

import Messages from '../components/messages/Messages';

export default class HomePage extends Component {
  render() {
    return (
      <div className="page-content-container fluid-container">
        <div className="row">
          <div className="col">
            <div className="">
              <div className="page-title" id="pageTitle">
                <h1>RANDOM RAMBLING PRESS</h1>
              </div>
            </div>
            <div className="">
              <div className="" id="pageContent">
              <Messages />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
