import React, { Component } from 'react';

export default class ProductsPage extends Component {
  render() {
    return (
      <div className="">
        <div className="">
          <div className="" id="pageTitle">
            <h1>PROJECTS</h1>
          </div>
        </div>
        <div className="">
          <div className="" id="pageContent">
            <p>Information coming soon!!!</p>
          </div>
        </div>
      </div>
    );
  }
}
